'use client';

import clsx from 'clsx';
import React, { useRef } from 'react';

import { useElementInViewport } from '@/shared/hooks/useIsVisible';

import FeatureCardOne from '../components/FeatureCardOne';

export default function FeatureLists() {
  const featureOneRef = useRef<HTMLDivElement>(null);
  const isVisibleOne = useElementInViewport(featureOneRef);

  const featureTwoRef = useRef<HTMLDivElement>(null);
  const isVisibleTwo = useElementInViewport(featureTwoRef);

  const featureThreeRef = useRef<HTMLDivElement>(null);
  const isVisibleThree = useElementInViewport(featureThreeRef);

  const featureFourRef = useRef<HTMLDivElement>(null);
  const isVisibleFour = useElementInViewport(featureFourRef);

  const featureFiveRef = useRef<HTMLDivElement>(null);
  const isVisibleFive = useElementInViewport(featureFiveRef);

  const featureSixRef = useRef<HTMLDivElement>(null);
  const isVisibleSix = useElementInViewport(featureSixRef);

  return (
    <div className="grid w-full grid-cols-1 gap-8 px-4 xs:px-6 sm:grid-cols-2 sm:px-10 lg:gap-14 lg:px-20 xl:gap-24">
      <div
        className={clsx('flex transition-transform duration-1000 ease-in', {
          'scale-100': isVisibleOne,
          'scale-110': !isVisibleOne,
        })}
        ref={featureOneRef}
      >
        <FeatureCardOne
          title="Screen recording"
          type="second"
          imageUrl="/images/feature1.jpeg"
          description="Canvaza allows users to record their screen, voice, and face to create an instantly shareable video."
        />
      </div>
      <div
        className={clsx('flex transition-transform duration-1000 ease-in', {
          'scale-100': isVisibleTwo,
          'scale-110': !isVisibleTwo,
        })}
        ref={featureTwoRef}
      >
        <FeatureCardOne
          title="Instant sharing"
          type="third"
          imageUrl="/images/feature2.jpg"
          description="Once a video is recorded, it can be shared immediately through a link. This eliminates the need for large file transfers."
        />
      </div>
      <div
        className={clsx('flex transition-transform duration-1000 ease-in', {
          'scale-100': isVisibleThree,
          'scale-110': !isVisibleThree,
        })}
        ref={featureThreeRef}
      >
        <FeatureCardOne
          title="Viewer insight"
          type="first"
          imageUrl="/images/feature3.jpg"
          description="Canvaza provides insights into who viewed the video and when, which is useful for tracking engagement."
        />
      </div>
      <div
        className={clsx('flex transition-transform duration-1000 ease-in', {
          'scale-100': isVisibleFour,
          'scale-110': !isVisibleFour,
        })}
        ref={featureFourRef}
      >
        <FeatureCardOne
          title="Commenting and Feedback"
          type="first"
          imageUrl="/images/feature4.jpeg"
          description="Viewers can leave comments and reactions on videos, facilitating asynchronous communication and feedback."
        />
      </div>
      <div
        className={clsx('flex transition-transform duration-1000 ease-in', {
          'scale-100': isVisibleFive,
          'scale-110': !isVisibleFive,
        })}
        ref={featureFiveRef}
      >
        <FeatureCardOne
          title="Cloud-Based"
          type="first"
          imageUrl="/images/feature5.jpg"
          description="Being cloud-based, Canvaza videos don't take up space on your device, and they are accessible from anywhere."
        />
      </div>
      <div
        className={clsx('flex transition-transform duration-1000 ease-in', {
          'scale-100': isVisibleSix,
          'scale-110': !isVisibleSix,
        })}
        ref={featureSixRef}
      >
        <FeatureCardOne
          title="Automatic Transcription"
          type="first"
          imageUrl="/images/feature6.jpeg"
          description="Canvaza automatically transcribes the audio of your videos creating accessible content for viewers."
        />
      </div>
    </div>
  );
}
