import clsx from 'clsx';
import React, { useRef } from 'react';

import Plans from '@/app/(protected)/(user)/upgrade';
import { useElementInViewport } from '@/shared/hooks/useIsVisible';

type PriceProps = {
  isLanding?: boolean;
  isPricingPage?: boolean;
  isPriceOverviewPage?: boolean;
};

export default function Price({
  isLanding,
  isPricingPage,
  isPriceOverviewPage,
}: PriceProps) {
  const pricingRef = useRef<HTMLDivElement>(null);
  const isVisiblePricingRef = useElementInViewport(pricingRef);
  return (
    <div
      className={clsx(
        'flex w-full items-center justify-center px-4 sm:px-6 md:px-12 lg:px-8 xl:px-0',
        {
          'bg-dark': isPricingPage,
          'bg-dark-lighter': !isPricingPage,
          'py-32': !isPriceOverviewPage,
          'py-14': isPriceOverviewPage,
        }
      )}
    >
      <div
        className={clsx(
          'flex w-full max-w-7xl flex-col items-center justify-center gap-16 transition-opacity duration-1000 ease-in',
          {
            'opacity-100': isVisiblePricingRef,
            'opacity-0': !isVisiblePricingRef,
          }
        )}
        ref={pricingRef}
      >
        <h1
          className={clsx(
            'text-center text-4xl font-semibold tracking-tight sm:text-5xl',
            {
              'text-dark-lighter': isPricingPage,
              'text-dark-700': isLanding,
              'text-dark-600 dark:text-dark-light':
                !isPricingPage && !isLanding,
            }
          )}
        >
          Choose A
          <span className="inline-block bg-gradient-to-r from-primary via-[#00F0FF] to-primary-lighter bg-clip-text text-transparent">
            Plan
          </span>
          To Get More Features
        </h1>
        <div className="flex h-full w-full px-0 xs:w-5/6 xs:px-6 sm:w-full sm:px-0">
          <Plans
            isLanding={isLanding}
            isPricingPage={isPricingPage}
            isPriceOverviewPage={isPriceOverviewPage}
          />
        </div>
      </div>
    </div>
  );
}
