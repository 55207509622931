'use client';

import clsx from 'clsx';
import Image from 'next/image';
import React, { useRef } from 'react';

import { FEATURES } from '@/shared/config/feature-flag';
import { useElementInViewport } from '@/shared/hooks/useIsVisible';

import {
  AppStoreIcon,
  PlayStoreIcon,
} from '../../../../../../shared/utils/svg';
import HeroBackground from './HeroBackground';
import StackedUsers from './StackedUsers';

const userImages = [
  './images/profile4.jpg',
  './images/profile3.jpg',
  './images/profile2.jpg',
  './images/profile1.jpg',
];

export default function Hero() {
  const homeRef = useRef<HTMLDivElement>(null);
  const isVisibleHomeRef = useElementInViewport(homeRef);
  const inviteTextRef = useRef<HTMLDivElement>(null);
  const isVisibleInviteText = useElementInViewport(inviteTextRef);

  const stackedUsersRef = useRef<HTMLDivElement>(null);
  const isVisibleStackedUser = useElementInViewport(stackedUsersRef);

  return (
    <div className="-lg:top-6 relative top-0 flex h-[100vh] w-full items-center justify-center bg-dark px-4 md:px-12 2xl:px-0">
      <div className="flex h-full w-full max-w-7xl flex-col items-center justify-center gap-8 pb-16 lg:gap-14">
        <div
          className={clsx(
            'flex h-full w-full flex-col items-center justify-center py-0 transition-opacity duration-1000 ease-in md:pt-16 lg:flex-row lg:justify-start lg:pt-0',
            {
              'opacity-100': isVisibleHomeRef,
              'opacity-0': !isVisibleHomeRef,
            }
          )}
          ref={homeRef}
        >
          <div className="flex w-full flex-col items-center gap-2 lg:items-start">
            <div>
              <h1 className="text-center lg:text-left">
                <span className="text-center text-4xl font-semibold text-dark-lighter lg:text-left lg:text-6xl">
                  Engage Your Team With
                </span>
                <span className="inline-block bg-gradient-to-r from-primary via-[#00F0FF] to-primary-lighter bg-clip-text p-1 text-center text-4xl font-bold text-transparent sm:py-0 lg:text-left lg:text-6xl">
                  Smarter Video
                </span>
              </h1>
            </div>
            <div>
              <p>
                <span className="text-lg font-semibold text-dark-light md:text-2xl lg:text-left">
                  Communication
                </span>{' '}
                <span className="inline-block bg-gradient-to-r from-primary via-[#00F0FF] to-primary-lighter bg-clip-text text-lg font-semibold text-transparent md:text-2xl lg:text-left">
                  On Canvaza
                </span>
              </p>
            </div>
          </div>
          <div className="z-10 flex w-11/12 flex-col items-center justify-center gap-0 py-12 md:w-2/3 lg:gap-10 lg:py-0">
            <div className="flex w-full justify-center lg:justify-end">
              <Image
                src="/images/sideImage1.png"
                width={460}
                height={530}
                alt="Canvaza Video Recording , Sharing and Team Collaboration Platform"
              />
            </div>
            {FEATURES.Attributes.downloadPlatformOptions && (
              <div className="flex w-full flex-wrap items-center justify-center gap-4 md:gap-8 lg:justify-end">
                <AppStoreIcon />
                <PlayStoreIcon />
              </div>
            )}
          </div>
        </div>
        <div className="hidden w-full flex-col items-start justify-end gap-6 pt-12 md:flex lg:pt-0">
          <div
            className={clsx(
              'flex flex-col transition-opacity duration-1000 ease-in',
              {
                'opacity-100': isVisibleInviteText,
                'opacity-0': !isVisibleInviteText,
              }
            )}
            ref={inviteTextRef}
          >
            <p className="text-xl font-semibold text-dark-lighter">
              Invite Friends and inside groups
            </p>
            <p className="text-xs text-dark-light md:text-sm">
              Invite your friends, groups and share videos
            </p>
          </div>
          <div
            className={clsx('flex transition-opacity duration-1000 ease-in', {
              'opacity-100': isVisibleStackedUser,
              'opacity-0': !isVisibleStackedUser,
            })}
            ref={stackedUsersRef}
          >
            <StackedUsers userImages={userImages} />
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 flex h-full w-full justify-start">
        <HeroBackground userImages={userImages} />
      </div>
    </div>
  );
}
