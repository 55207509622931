import React from 'react';

type StackedUsersProps = {
  userImages: string[];
};

export default function StackedUsers({ userImages }: StackedUsersProps) {
  return (
    <div className="flex w-full items-center -space-x-4">
      {userImages.map((userImage, index) => (
        <div
          key={index}
          className="flex h-12 w-12 items-center justify-center rounded-full lg:h-16 lg:w-16"
        >
          <img
            src={userImage}
            alt="canvaza users images, invite, share and collaborate with your peers"
            className="h-full w-full rounded-full object-cover"
            key={index}
          />
        </div>
      ))}
    </div>
  );
}
