import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

export default function UserComponent() {
  return (
    <div className="relative w-full">
      <img
        src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt={'Record and upload within seconds using canvaza'}
        className="h-16 w-16 rounded-full object-cover"
      />
      <div className="absolute bottom-2 left-2 flex gap-1">
        <XMarkIcon className="w-4 stroke-dark-lighter" />
        <div className="h-4 w-4 rounded-full border-8 border-dark-lighter/40 bg-dark-lighter"></div>
      </div>
    </div>
  );
}
