import React from 'react';

import { BiggerHomeHeroSvg } from '@/shared/utils/svg';

import StackedUsers from './StackedUsers';

type HeroBackgroundProps = {
  userImages: string[];
};
export default function HeroBackground({ userImages }: HeroBackgroundProps) {
  return (
    <div className="relative flex w-full items-end justify-start">
      <BiggerHomeHeroSvg className="h-80  w-[960px] lg:h-[430px] lg:w-[760px] xl:w-[960px] 2xl:w-[1280px]" />
      <div className="absolute bottom-14 left-6 flex w-full flex-col items-start justify-center gap-3 px-4 xs:left-10 md:inset-0 md:left-16 md:hidden">
        <div className="flex flex-col">
          <p className="text-xl font-semibold text-dark-lighter">
            Invite Friends and groups
          </p>
          <p className="text-xs text-dark-light md:text-sm">
            Invite your friends, groups and share videos
          </p>
        </div>
        <div>
          <StackedUsers userImages={userImages} />
        </div>
      </div>
    </div>
  );
}
