'use client';

import Image from 'next/image';
import React from 'react';

export default function Transcript() {
  return (
    <div className="flex w-full items-center justify-center bg-gradient-to-br from-dark-lighter via-dark-lighter to-info-lighter/15 py-16 lg:py-32">
      <div className="flex w-full max-w-7xl flex-col items-center justify-center gap-16">
        <p className="w-11/12 text-center text-3xl font-semibold lg:text-5xl">
          Transcription and&nbsp;
          <span className="inline-block bg-gradient-to-r from-primary via-[#00F0FF] to-primary-lighter bg-clip-text text-transparent">
            Accessibility
          </span>
        </p>
        <div className="flex h-full w-5/6 flex-col-reverse items-center justify-center rounded-md bg-dark-lighter shadow-md shadow-primary/20 sm:w-11/12 sm:flex-row md:w-5/6">
          <Image
            src="/images/transcript.jpg"
            width={576}
            height={384}
            alt="transcription and accessibility"
            className="rounded-md"
          />
          <div className="flex h-full max-h-96 w-full items-center justify-center rounded-e-md  p-6 sm:w-1/2">
            <div className="flex flex-col gap-4">
              <p className="text-xl font-semibold lg:text-3xl">
                {" Generates a transcript of the video's audio."}
              </p>
              <p className="text-xs md:text-sm">
                {
                  " Canvaza automatically generates a transcript of the video's audio , which enhances accessibility and allows for searchable content."
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
