import Link from 'next/link';
import React from 'react';

import { SolidInstagramIcon, SolidLinkedInIcon } from '@/shared/utils/svg';

import type { CategorizedLinkItemType } from '../header';
import CategorizedLink from './CategorizedLink';
import SocialMedia from './SocialMedia';

export type SocialMediaItemType = {
  icon: React.ReactNode;
  href: string;
  tooltipContent: string;
};

const menuList: CategorizedLinkItemType[] = [
  {
    category: 'Use cases',
    items: [
      {
        href: '/casestudy/sales',
        title: 'Sales',
      },

      {
        href: '/casestudy/education',
        title: 'Education',
      },

      {
        href: '/casestudy/engineering',
        title: 'Engineering',
      },
    ],
  },

  {
    category: 'Resources',
    items: [
      {
        href: '/pricing',
        title: 'Pricing',
      },
      {
        href: '#',
        title: 'Tutorials',
      },
    ],
  },

  {
    category: 'Company',
    items: [
      {
        href: '/about',
        title: 'About Us',
      },

      {
        href: '/contact',
        title: 'Contact Us',
      },
    ],
  },
];

const socialMediaList: SocialMediaItemType[] = [
  {
    href: 'https://www.linkedin.com/company/canvaza',
    tooltipContent: 'LinkedIn',
    icon: <SolidLinkedInIcon className="w-4" />,
  },
  {
    href: 'https://www.instagram.com/can.vaza?igsh=bjNtbmdnc3Y1bDEz',
    tooltipContent: 'Instagram',
    icon: <SolidInstagramIcon className="w-4" />,
  },
];

export default function Footer() {
  return (
    <>
      <div className="flex w-screen items-center justify-center bg-dark py-14 sm:py-24">
        <div className="flex w-full max-w-7xl flex-col items-center justify-around gap-24 px-10 py-12 text-dark-lighter md:py-20 lg:h-full lg:gap-36 2xl:px-0">
          <div className="flex w-full flex-col items-start justify-start gap-12 md:flex-row md:gap-8">
            <div className="flex w-full flex-col items-start justify-between gap-6 sm:flex-row md:w-1/3 md:flex-col md:justify-start">
              <div className="flex flex-col gap-6">
                <p className="text-4xl font-semibold">CANVAZA</p>
                <p className="w-11/12 text-sm opacity-40 xs:w-4/5 sm:w-2/3">
                  Step into the era of personalized video communication with
                  Canvaza
                </p>
              </div>
              <SocialMedia mediaItems={socialMediaList} />
            </div>

            <div className="flex w-full flex-wrap items-start justify-between md:w-2/3">
              {menuList.map((menu, key) => (
                <CategorizedLink key={key} categorizedItem={menu} />
              ))}
            </div>
          </div>
          <div className="flex w-full flex-wrap items-center justify-between gap-6">
            <div className="flex flex-col items-start gap-2 xs:gap-1">
              <p className="text-left text-xs text-dark-light">
                © Canvaza. All right reserved
              </p>
              <div className="flex flex-wrap gap-2">
                <Link
                  href="/terms-and-conditions"
                  className="border-r border-dark-lighter pr-1 text-xs text-dark-light/70 xs:pr-3"
                >
                  Terms and conditions
                </Link>
                <Link
                  href="/privacy-policy"
                  className="px-1 text-xs text-dark-light/70 xs:px-3"
                >
                  Privacy policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
