import Image from 'next/image';
import React from 'react';

import RecordingTab from './RecordingTab';

type HorizontalCardProps = {
  type: 'one' | 'two' | 'three';
};
export default function HorizontalCard({ type }: HorizontalCardProps) {
  return (
    <>
      {type === 'one' && (
        <div className="relative w-full pt-2 md:pt-0">
          <div className="relative h-[260px] w-full lg:h-[360px]">
            <Image
              src="/images/step1.jpg"
              fill
              alt="Canvaza Video Recording"
              className="rounded"
            />
          </div>
          <div className="absolute bottom-3 flex w-full shrink justify-center bg-dark-lighter/40 py-3">
            <RecordingTab />
          </div>
        </div>
      )}
      {type === 'two' && (
        <div className="relative w-full">
          <div className="relative h-[260px] w-full lg:h-[360px]">
            <Image
              src="/images/step2.jpg"
              fill
              alt="Canvaza Video Sharing"
              className="rounded"
            />
          </div>
          <div className="absolute inset-0 flex w-full rounded bg-dark-500/30">
            <div className="relative flex w-full">
              <div className="absolute bottom-5 right-3 hidden w-[360px] justify-end  sm:flex md:hidden lg:flex lg:w-[480px] xl:w-[620px]">
                <div className="relative flex w-full justify-end">
                  <div className="absolute bottom-10 right-48">
                    <div className="relative h-[130px] w-[220px]">
                      <Image
                        src="/images/arrows.png"
                        fill
                        alt="Invite Friends to Canvaza"
                      />
                    </div>
                  </div>
                  <div className="relative h-[130px] w-[220px]">
                    <Image
                      src="/images/step22.jpg"
                      fill
                      alt="Invite Friends to Canvaza"
                      className="rounded-md border-8 border-primary-light/80"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {type === 'three' && (
        <div className="relative w-full pt-2 md:pt-0">
          <div className="relative h-[260px] w-full lg:h-[360px]">
            <Image
              src="/images/step3.jpg"
              fill
              alt="Invite Friends to Canvaza"
              className="rounded"
            />
          </div>
          <div className="absolute bottom-3 hidden w-full items-end justify-between gap-6 bg-dark-500/30 px-4 sm:flex md:hidden lg:flex">
            <div className="relative h-[134px] w-1/2 xl:w-1/3">
              <Image
                src="/images/step31.jpg"
                fill
                alt="Invite Friends to Canvaza"
                className="rounded-md border-4 border-primary-lighter"
              />
            </div>
            <div className="relative h-[134px] w-1/2 xl:w-1/3">
              <Image
                src="/images/step32.jpg"
                fill
                alt="Invite Friends to Canvaza"
                className="rounded-md border-4 border-primary-lighter"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
