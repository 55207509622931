import Accessibility from './accessiblity';
import Features from './features';
import Hero from './hero';
import HowItWorks from './how-it-works';
import Price from './price';
import Transcript from './transcript';
import Trusted from './trusted-by';
import UseCase from './use-case';

export default function Landing() {
  return (
    <div className="h-full w-screen bg-dark-lighter text-dark-700">
      <Hero />
      <Features />
      <Trusted />
      <HowItWorks />
      <Transcript />
      <Price isLanding />
      <Accessibility />
      <UseCase />
    </div>
  );
}
