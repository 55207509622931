import {
  MinusCircleIcon,
  PaintBrushIcon,
  PauseCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import React from 'react';

export default function RecordingTab() {
  return (
    <div className="flex w-11/12 items-center justify-between gap-1 rounded-full bg-dark p-3 sm:w-5/6 md:w-11/12 lg:w-3/4 lg:gap-3">
      <div className="flex w-1/5 justify-center border-r border-dark-light/30">
        <PaintBrushIcon className="h-6 w-6 text-dark-light sm:h-8 sm:w-8" />
      </div>
      <div className="flex w-1/2 justify-center border-r border-dark-light/30">
        <div className="flex items-center gap-1 sm:gap-4">
          <PauseCircleIcon className="h-6 w-6 stroke-2 text-dark-light sm:h-8 sm:w-8" />
          <span className="text-xs font-semibold text-dark-light sm:text-base">
            0:12
          </span>
          <span className="rounded bg-red-600 p-2 sm:p-3"></span>
        </div>
      </div>
      <div className="flex w-1/4 justify-center ">
        <div className="flex items-center gap-1 sm:gap-3">
          <div className="flex h-6 w-6 items-center justify-center rounded-full bg-dark-light/30 sm:h-9 sm:w-9">
            <TrashIcon className="h-4 w-4 text-dark-light sm:h-6 sm:w-6" />
          </div>
          <MinusCircleIcon className="h-6 w-6 text-dark-light" />
        </div>
      </div>
    </div>
  );
}
