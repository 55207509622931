import Link from 'next/link';
import React from 'react';

import type { CategorizedLinkItemType } from '../header';

type CategorizedLinkProps = { categorizedItem: CategorizedLinkItemType };
export default function CategorizedLink({
  categorizedItem,
}: CategorizedLinkProps) {
  return (
    <div className="space-y-6 pb-4">
      <p className="text-sm font-semibold uppercase md:text-base lg:text-lg">
        {categorizedItem.category}
      </p>
      <ul className="space-y-4">
        {categorizedItem.items.map((item, key) => (
          <li key={key} className="text-xs md:text-sm lg:text-base">
            <Link href={item.href}>{item.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
