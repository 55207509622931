import Link from 'next/link';
import React from 'react';

import type { SocialMediaItemType } from '.';

type SocialMediaProps = {
  mediaItems: SocialMediaItemType[];
};
export default function SocialMedia({ mediaItems }: SocialMediaProps) {
  return (
    <div className="flex flex-row items-center justify-end gap-6">
      {mediaItems.map((item, key) => (
        <Link
          key={key}
          href={item.href}
          target="_blank"
          className="flex h-10 w-10 items-center justify-center rounded-full bg-dark-lighter/20"
        >
          {item.icon}
        </Link>
      ))}
    </div>
  );
}
