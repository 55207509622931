'use client';

import clsx from 'clsx';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';

import { useElementInViewport } from '@/shared/hooks/useIsVisible';

export default function Accessibility() {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setHeight((ref.current.clientWidth * 9) / 16);
    }
  }, [ref]);

  const accessibleRef = useRef<HTMLDivElement>(null);
  const isVisibleRef = useElementInViewport(accessibleRef);

  return (
    <div className="flex w-full items-center justify-center bg-gradient-to-r from-info-lighter/20 to-dark-lighter px-12 py-32 text-dark-700">
      <div className="flex w-full max-w-7xl items-center justify-center">
        <div
          className="flex w-full flex-col-reverse items-end justify-center gap-4 md:items-center md:gap-6 lg:flex-row lg:justify-between lg:gap-12"
          ref={accessibleRef}
        >
          <div
            className={clsx(
              'relative w-full max-w-2xl transition-opacity duration-500 ease-in md:max-w-xl lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl',
              {
                'opacity-100': isVisibleRef,
                'opacity-0': !isVisibleRef,
              }
            )}
            ref={ref}
            style={{ height: `${height}px` }}
          >
            <div className="aspect-video h-[calc(100%*(9/16))] w-full ">
              <Image
                fill
                src={'/images/videoEdit.png'}
                className="rounded-lg object-cover"
                alt="With Canvaza video transcription is available"
              />
            </div>
            <div className="absolute bottom-2 right-8 flex w-full justify-end sm:bottom-10 sm:right-24">
              <div className="flex w-3/4 flex-col gap-4 sm:gap-4 md:w-2/3 lg:w-1/2">
                <div
                  className={clsx(
                    'w-full rounded bg-dark-light/60 p-2 transition-transform delay-75 duration-1000 ease-in sm:p-4',
                    {
                      '-translate-3': isVisibleRef,
                      'translate-y-3': !isVisibleRef,
                    }
                  )}
                >
                  <p className="text-xs font-semibold leading-snug 2xl:text-sm">
                    {'Hola guys, how are you all doing'}
                  </p>
                </div>
                <div
                  className={clsx(
                    'ml-8 w-full rounded bg-dark-light/60 p-2 transition-transform delay-300 duration-1000 ease-in sm:p-4 ',
                    {
                      '-translate-3': isVisibleRef,
                      'translate-y-3': !isVisibleRef,
                    }
                  )}
                >
                  <p className="text-xs font-semibold leading-snug 2xl:text-sm">
                    {"Let's get started on today's lesson"}
                  </p>
                </div>
                <div
                  className={clsx(
                    'hidden w-full rounded bg-dark-light/60 p-2 transition-transform delay-500 duration-1000 ease-in sm:p-4 md:flex ',
                    {
                      '-translate-3': isVisibleRef,
                      'translate-y-3': !isVisibleRef,
                    }
                  )}
                >
                  <p className="text-xs font-semibold leading-snug 2xl:text-sm">
                    {'Here are 5 ways to record on using canvaza'}
                  </p>
                </div>
                <div
                  className={clsx(
                    'ml-8 hidden w-full rounded bg-dark-light/60 p-2 transition-transform delay-700 duration-1000 ease-in sm:p-4 md:flex ',
                    {
                      '-translate-3': isVisibleRef,
                      'translate-y-3': !isVisibleRef,
                    }
                  )}
                >
                  <p className="text-xs font-semibold leading-snug 2xl:text-sm">
                    {'First open the canvaza on your browser'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col items-end justify-end gap-14 pb-2 md:items-center lg:w-1/2 lg:items-end lg:justify-start">
            <div>
              <p className="text-right text-4xl font-semibold md:text-center lg:text-right xl:text-5xl">
                We Go Beyond Transcription
              </p>
            </div>
            <div className="flex flex-col items-end gap-4 md:items-center lg:items-end">
              <p className="w-full text-right text-xs sm:w-2/3 md:text-center lg:w-full lg:text-right lg:text-sm">
                {
                  "Canvaza provides content summarization using advanced algorithms to distill key insights from your videos. Canvaza saves time, enhances accessibility, and empowers users to quickly grasp essential information for impactful decision-making. Whether you're a content creator, business professional, or educator, Canvaza transforms your video library experience, making it efficient and impactful."
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
