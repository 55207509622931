'use client';

import clsx from 'clsx';
import React, { useRef } from 'react';

import { useElementInViewport } from '@/shared/hooks/useIsVisible';

import UseCaseCards from './UseCaseCards';

export default function UseCase() {
  const titleRef = useRef<HTMLDivElement>(null);
  const isVisible = useElementInViewport(titleRef);
  return (
    <div className="flex w-full items-center justify-center  bg-gradient-to-r from-info-lighter/20 to-dark-lighter px-12 pb-32 pt-12 sm:pt-20">
      <div className="flex w-full max-w-7xl flex-col items-center justify-center gap-20">
        <p
          className={clsx(
            'inline-block bg-gradient-to-r from-primary from-50% via-[#00F0FF] via-30% to-primary-light to-20% bg-clip-text text-4xl font-semibold text-transparent transition-opacity duration-1000 ease-in lg:text-5xl ',
            {
              'opacity-100': isVisible,
              'opacity-0': !isVisible,
            }
          )}
          ref={titleRef}
        >
          UseCases
        </p>
        <UseCaseCards />
      </div>
    </div>
  );
}
