import clsx from 'clsx';

import Footer from './footer';
import Header from './header';

type ExternalLayoutProps = {
  children: React.ReactNode;
};

export default function ExternalLayout({ children }: ExternalLayoutProps) {
  return (
    <div className={clsx('relative h-full w-full ')}>
      <div className="flex h-[7%] w-full">
        <Header />
      </div>
      <div className="flex h-[93%] w-full flex-col gap-0 overflow-y-auto overflow-x-hidden">
        <section className="w-full">{children}</section>
        <Footer />
      </div>
    </div>
  );
}
