import React from 'react';

import FeatureLists from './FeatureLists';

export default function Features() {
  return (
    <div className="flex w-full items-center justify-center py-28 lg:py-40">
      <div className="flex w-full max-w-7xl flex-col items-center justify-center gap-10 sm:gap-20">
        <p className="text-center text-3xl font-semibold xs:text-4xl lg:text-6xl">
          Powerful Features
        </p>
        <FeatureLists />
      </div>
    </div>
  );
}
