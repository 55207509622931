'use client';

import clsx from 'clsx';
import React, { useRef } from 'react';

import { useElementInViewport } from '@/shared/hooks/useIsVisible';
import { GooglePayIcon, StripeIcon } from '@/shared/utils/svg';

export default function Trusted() {
  const trustedRef = useRef<HTMLDivElement>(null);
  const isVisibleRef = useElementInViewport(trustedRef);

  return (
    <div
      className="flex w-full items-center justify-center bg-dark px-12 py-20 xs:h-72 xs:py-0"
      ref={trustedRef}
    >
      <div className="flex h-full w-full max-w-7xl flex-col items-center justify-between gap-14 xs:flex-row xs:gap-6 sm:justify-center md:gap-16">
        <div
          className={clsx(
            'flex w-full items-center justify-center rounded border-b-2 border-primary py-0 transition-opacity duration-1000 ease-in xs:h-56 xs:w-1/2 xs:border-b-0 xs:border-r-2 xs:py-14 md:justify-end',
            {
              'opacity-100': isVisibleRef,
              'opacity-0': !isVisibleRef,
            }
          )}
        >
          <p className="px-0 py-4 text-center text-3xl font-semibold text-dark-lighter xs:text-left xs:text-2xl sm:text-3xl md:px-4 lg:px-8 lg:text-5xl ">
            Trusted By
          </p>
        </div>
        <div
          className={clsx(
            'flex  w-11/12 items-center justify-start gap-6 xs:h-56 sm:w-1/2 md:gap-12'
          )}
        >
          <div
            className={clsx('flex transition-all duration-500 ease-in', {
              'translate-x-3 opacity-100': isVisibleRef,
              '-translate-x-3 opacity-0': !isVisibleRef,
            })}
          >
            <GooglePayIcon className="h-8 w-24 sm:h-12 sm:w-36" />
          </div>
          <div
            className={clsx(
              'flex transition-all delay-500 duration-1000 ease-in',
              {
                'translate-x-3 opacity-100': isVisibleRef,
                '-translate-x-3 opacity-0': !isVisibleRef,
              }
            )}
          >
            <StripeIcon className="h-8 w-24 sm:h-12 sm:w-36" />
          </div>
        </div>
      </div>
    </div>
  );
}
