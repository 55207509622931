'use client';

import clsx from 'clsx';
import React, { useRef } from 'react';

import { useElementInViewport } from '@/shared/hooks/useIsVisible';

import HorizontalCard from '../components/HorizontalCard';

export default function HowItWorks() {
  const stepOneRef = useRef<HTMLDivElement>(null);
  const isVisibleStepOne = useElementInViewport(stepOneRef);

  // const stepRef = useRef<HTMLDivElement>(null);
  // const isVisibleStepOne = useElementInViewport(stepOneRef);

  const stepTwoRef = useRef<HTMLDivElement>(null);
  const isVisibleStepTwo = useElementInViewport(stepTwoRef);

  const stepThreeRef = useRef<HTMLDivElement>(null);
  const isVisibleStepThree = useElementInViewport(stepThreeRef);

  return (
    <div className="flex w-full items-center justify-center bg-gradient-to-br from-info-lighter/20 via-dark-lighter to-dark-lighter px-4 py-28 xs:px-10 sm:px-16 xl:px-12">
      <div className=" flex h-full w-full max-w-7xl flex-col items-center justify-center gap-16 ">
        <div className="flex w-full items-center justify-center">
          <p className="text-center text-4xl font-semibold md:text-6xl">
            How Canvaza Works
          </p>
        </div>
        <div className="flex w-full flex-col items-center justify-center gap-20">
          <div
            className={clsx(
              'flex w-full flex-col-reverse items-start justify-center gap-6 transition-opacity duration-1000 ease-in md:flex-row  md:justify-between',
              {
                'opacity-100': isVisibleStepOne,
                'opacity-0': !isVisibleStepOne,
              }
            )}
            ref={stepOneRef}
          >
            <div
              className={clsx(
                'w-full pt-2 transition-transform duration-1000 ease-in md:w-1/2 md:pt-0'
              )}
            >
              <HorizontalCard type="one" />
            </div>
            <div
              className={clsx(
                'flex w-full flex-col items-end justify-start transition-transform duration-1000 md:w-1/2 md:justify-end lg:w-1/3'
              )}
            >
              <div className="flex w-full flex-col items-start justify-start gap-3 pb-6 md:items-end md:justify-end lg:flex-row lg:items-center lg:gap-8">
                <div className="flex h-20 w-20 items-center justify-center rounded-full bg-gradient-to-br from-info-lighter from-0% lg:h-24 lg:w-24">
                  <p className="text-xl font-semibold text-dark-600 lg:text-3xl">
                    1
                  </p>
                </div>
                <p className="text-xl font-semibold lg:text-3xl">Recording</p>
              </div>
              <div className="w-full">
                <p className="text-left text-xs md:text-right lg:text-sm">
                  {
                    " Canvaza enables easy recording with a click on its desktop or mobile app icon. It offers flexible recording options, including screen capture, webcam use, or both. Users can select their recording area-full screen, a specific window, or a custom area-and then start recording. Additionally, they can add audio commentary during the recording, enriching the video's communication value"
                  }
                </p>
              </div>
            </div>
          </div>

          <div
            className={clsx(
              'flex w-full flex-col items-start justify-between gap-6 transition-opacity duration-1000  ease-in md:flex-row md:items-center',
              {
                'opacity-100': isVisibleStepTwo,
                'opacity-0': !isVisibleStepTwo,
              }
            )}
            ref={stepTwoRef}
          >
            <div
              className={clsx(
                'flex w-full flex-col items-start justify-start transition-transform duration-1000 ease-in md:w-1/2  lg:w-1/3'
              )}
            >
              <div className="flex w-full flex-col items-start justify-start gap-3 pb-6 lg:flex-row lg:items-center lg:gap-8">
                <div className="flex h-20 w-20 items-center justify-center rounded-full bg-gradient-to-br from-info-lighter from-0% lg:h-24 lg:w-24">
                  <p className="text-xl font-semibold text-dark-600 lg:text-3xl">
                    2
                  </p>
                </div>
                <p className="text-xl font-semibold lg:text-3xl">Share video</p>
              </div>
              <div className="w-full">
                <p className="text-left text-sm">
                  Once a video is recorded on Canvaza, it generates a shareable
                  link, which can be distributed via email, messaging apps, or
                  embedded in websites and documents. Canvaza also offers
                  privacy controls to manage who can view the video.
                </p>
              </div>
            </div>
            <div
              className={clsx(
                'w-full transition-transform duration-1000 ease-in  md:w-1/2'
              )}
            >
              <HorizontalCard type="two" />
            </div>
          </div>

          <div
            className={clsx(
              'flex w-full flex-col-reverse items-start justify-center gap-6  transition-opacity duration-1000  ease-in  md:flex-row md:items-center md:justify-between',
              {
                'opacity-100': isVisibleStepThree,
                'opacity-0': !isVisibleStepThree,
              }
            )}
            ref={stepThreeRef}
          >
            <div
              className={clsx(
                'w-full pt-2 transition-transform duration-1000 ease-in md:w-1/2 md:pt-0'
              )}
            >
              <HorizontalCard type="three" />
            </div>
            <div
              className={clsx(
                'flex w-full flex-col items-end justify-start transition-transform duration-1000 ease-in md:w-1/2 md:justify-end lg:w-1/3'
              )}
            >
              <div className="flex w-full flex-col items-start justify-end gap-3 pb-6 md:items-end lg:flex-row lg:items-center lg:gap-8">
                <div className="flex h-20 w-20 items-center justify-center rounded-full bg-gradient-to-br from-info-lighter from-0% lg:h-24 lg:w-24">
                  <p className="text-xl font-semibold text-dark-600 lg:text-3xl">
                    3
                  </p>
                </div>
                <p className="text-xl font-semibold lg:text-3xl">
                  Invite friends
                </p>
              </div>
              <div className="w-full">
                <p className="text-left text-sm md:text-right">
                  Viewers can conveniently watch shared Canvaza videos without
                  needing an account and interact by commenting, using emojis,
                  or responding with their own video. The creator gets
                  notifications about views and comments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
