'use client';

import React from 'react';

import ExternalLayout from '@/common/component/layout/external';
import Landing from '@/common/component/layout/external/landing';

export default function LandingPage() {
  return (
    <ExternalLayout>
      <Landing />
    </ExternalLayout>
  );
}
