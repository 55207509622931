import Image from 'next/image';

import {
  SolidFacebookIcon,
  SolidInstagramIcon,
  SolidLinkedInIcon,
  SolidTwitterIcon,
} from '../../../../../../shared/utils/svg';
import RecordingTab from './RecordingTab';
import UserComponent from './UserComponent';

type FeatureCardOneProps = {
  title: string;
  description: string;
  imageUrl: string;
  type: 'first' | 'second' | 'third';
};

export default function FeatureCardOne({
  title,
  description,
  imageUrl,
  type,
}: FeatureCardOneProps) {
  return (
    <div className="flex w-full flex-col items-center justify-between rounded-lg bg-dark-lighter shadow-2xl shadow-primary-light/10 hover:shadow-primary/40">
      <div className="flex flex-col items-start gap-2 p-12">
        <p className="text-xl font-semibold xl:text-4xl">{title}</p>
        <p className="text-xs lg:text-sm">{description}</p>
      </div>
      <div className="relative h-72 w-full sm:h-64 lg:h-96">
        <Image
          src={imageUrl}
          alt={title}
          width={512}
          height={384}
          className="h-full w-full rounded-md object-cover"
        />
        {type === 'second' && (
          <div className="absolute -top-6 left-6 flex w-full flex-col gap-4">
            <UserComponent />
            <RecordingTab />
          </div>
        )}
        {type === 'third' && (
          <div className="absolute -top-4 right-2 flex w-full justify-end">
            <div className="flex items-center justify-end gap-2">
              <SolidLinkedInIcon className="w-7" />
              <SolidTwitterIcon className="w-7" />
              <SolidFacebookIcon className="w-7" />
              <SolidInstagramIcon className="w-7" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
