'use client';

import {
  AcademicCapIcon,
  ChartBarSquareIcon,
  CodeBracketIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import { useRef } from 'react';

import {
  EDUCATION,
  ENGINEERING,
  SALES,
} from '@/shared/config/route-links.config';
import { useElementInViewport } from '@/shared/hooks/useIsVisible';

export default function UseCaseCards() {
  const useCaseRef = useRef<HTMLDivElement>(null);
  const isVisibleUseCase = useElementInViewport(useCaseRef);
  const route = useRouter();
  const handelUseCaseRedirect = (
    type: 'education' | 'sales' | 'engineering'
  ) => {
    if (type === 'education') {
      route.push(EDUCATION.url);
    }
    if (type === 'sales') {
      route.push(SALES.url);
    }
    if (type === 'engineering') {
      route.push(ENGINEERING.url);
    }
  };
  return (
    <div
      className="flex w-full flex-col items-center justify-center gap-6 sm:flex-row sm:justify-between"
      ref={useCaseRef}
    >
      <div
        className={clsx(
          'flex cursor-pointer justify-center transition-opacity  duration-1000 ease-in',
          {
            'opacity-100': isVisibleUseCase,
            'opacity-0': !isVisibleUseCase,
          }
        )}
      >
        <div
          className="flex h-48 w-48 flex-col items-center justify-center gap-3 rounded-full border-[0.5px] border-primary-light bg-dark-lighter shadow-none transition-shadow ease-in-out hover:border-t-4 hover:shadow-md hover:shadow-primary-lighter/30 lg:h-64 lg:w-64 xl:h-80 xl:w-80"
          onClick={() => handelUseCaseRedirect('education')}
        >
          <AcademicCapIcon className="w-16 lg:w-24" />
          <p className="text-sm font-semibold lg:text-base">For Education</p>
        </div>
      </div>
      <div
        className={clsx(
          'flex cursor-pointer justify-center transition-opacity delay-300 duration-1000 ease-in',
          {
            'opacity-100': isVisibleUseCase,
            'opacity-0': !isVisibleUseCase,
          }
        )}
      >
        <div
          className="flex h-48 w-48 flex-col items-center justify-center gap-3 rounded-full border-[0.5px] border-primary-light bg-dark-lighter shadow-none transition delay-300 ease-in-out hover:border-t-4 hover:shadow-md hover:shadow-primary-lighter/30 lg:h-64 lg:w-64 xl:h-80 xl:w-80"
          onClick={() => handelUseCaseRedirect('engineering')}
        >
          <CodeBracketIcon className="w-16 lg:w-24" />
          <p className="text-sm font-semibold lg:text-base">For Engineering</p>
        </div>
      </div>
      <div
        className={clsx(
          'flex cursor-pointer justify-center transition-opacity delay-500 duration-1000 ease-in',
          {
            'opacity-100': isVisibleUseCase,
            'opacity-0': !isVisibleUseCase,
          }
        )}
      >
        <div
          className="flex h-48 w-48 flex-col items-center justify-center gap-3 rounded-full border-[0.5px] border-primary-light bg-dark-lighter shadow-none transition delay-300 ease-in-out hover:border-t-4 hover:shadow-md hover:shadow-primary-lighter/30 lg:h-64 lg:w-64 xl:h-80 xl:w-80"
          onClick={() => handelUseCaseRedirect('sales')}
        >
          <ChartBarSquareIcon className="w-16 lg:w-24" />
          <p className="text-sm font-semibold lg:text-base">For Sales</p>
        </div>
      </div>
    </div>
  );
}
